import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <div className="light-bg">
      <div
        className={"container light-bg text-center py-5"}
        style={{"minHeight": "600px;"}}
      >
        <h1>404 - NOT FOUND</h1>
        <p>Unfortunately, that page no longer exists on our website.</p>
        <p>
          <a class="btn btn-primary" href="/">Click here to return to the homepage</a>
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
